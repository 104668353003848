<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>招聘管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="addClick">添加</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="employmentList"
      v-bind="otherProps"
      :listCount="employmentTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #operation="scope">
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>
    <add-edit-product-dialog :params="addEditInfo" @close="addEditDialogClose"></add-edit-product-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/employmentConfig.js'
import { deleteEmployment } from '@/api/employment/employment'
import addEditProductDialog from './cnps/add-edit-product-dialog.vue'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      queryInfo: {
        cur: 1,
        size: 20,
      },
      addEditInfo: {
        show: false,
        data: null,
      }
    }
  },
  components: {
    TableUi,
    addEditProductDialog,
  },
  mounted() {
    this.getDataList()
  },
  computed: {
    ...mapState('employment', ['employmentList', 'employmentTotal'])
  },
  methods: {
    ...mapActions('employment', ['getEmploymentList']),
    getDataList () {
      this.getEmploymentList(this.queryInfo)
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getDataList()
    },
    selectChange() {
      this.queryInfo.cur = 1
      this.getDataList()
    },
    // 增加
    addClick() {
      this.addEditInfo.show = true
    },
    // 编辑
    async editClick(row) {
      this.addEditInfo.data = row
      this.addEditInfo.show = true
    },
    // 关闭窗口
    addEditDialogClose () {
      this.addEditInfo.show = false;
      this.addEditInfo.data = null;
      this.getDataList()
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteEmployment(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getDataList()
      }
    }
  },
  
}
</script>

<style lang="less" scoped>
  .el-button {
    margin-left: 10px;
  }
</style>