<template>
  <div>
    <el-dialog :title="this.params.data ? '编辑' : '添加'"  :visible.sync="params.show" fullscreen :before-close="close" size="60%" :with-header="false" @open="openDialog">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
        <el-form-item label="语言">
          <el-switch
            v-model="isEnglish"
            active-text="英文"
            inactive-text="中文">
          </el-switch>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="工作名称(中文)" prop="work_name_cn">
          <el-input v-model="ruleForm.work_name_cn" placeholder="请输入工作名称(中文)"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="工作名称(英文)" prop="work_name_us">
          <el-input v-model="ruleForm.work_name_us" placeholder="请输入工作名称(英文)"></el-input>
        </el-form-item>
        <el-form-item v-show="!isEnglish" label="所属部门(中文)" prop="department_cn">
          <el-input v-model="ruleForm.department_cn" placeholder="请输入所属部门(中文)"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="所属部门(英文)" prop="department_us">
          <el-input v-model="ruleForm.department_us" placeholder="请输入所属部门(英文)"></el-input>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="工作地区(中文)" prop="work_region_cn">
          <el-input v-model="ruleForm.work_region_cn" placeholder="请输入工作地区(中文)"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="工作地区(英文)" prop="work_region_us">
          <el-input v-model="ruleForm.work_region_us" placeholder="请输入工作地区(英文)"></el-input>
        </el-form-item>

        <el-form-item label="薪资" prop="wage">
          <el-input v-model="ruleForm.wage" placeholder="请输入薪资"></el-input>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="要求(中文)" prop="requirement_cn">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 4}" v-model="ruleForm.requirement_cn" placeholder="请输入要求(中文)"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="要求(英文)" prop="requirement_us">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 4}" v-model="ruleForm.requirement_us" placeholder="请输入要求(英文)"></el-input>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="详情(中文)" prop="detail_cn">
          <tinymce v-model="ruleForm.detail_cn"></tinymce>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="详情(英文)" prop="detail_us">
          <tinymce v-model="ruleForm.detail_us"></tinymce>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { editEmployment, addEmployment } from '@/api/employment/employment'
import tinymce from '@/components/common/tinymce.vue'
export default {
  components: {
    tinymce,
  },
  data() {
    return {
      isEnglish: false,
      ruleForm: {
        id: null,
        work_name_cn: '',
        work_name_us: '',
        department_cn: '',
        department_us: '',
        work_region_cn: '',
        work_region_us: '',
        wage: '',
        requirement_cn: '',
        requirement_us: '',
        detail_cn: '',
        detail_us: '',
      },
      rules: {}
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        work_name_cn: '',
        work_name_us: '',
        department_cn: '',
        department_us: '',
        work_region_cn: '',
        work_region_us: '',
        wage: '',
        requirement_cn: '',
        requirement_us: '',
        detail_cn: '',
        detail_us: '',
      }
      this.$emit('close')
    },
    openDialog () {
      this.isEnglish = false
      if (this.params.data) {
        this.ruleForm = this.params.data
        this.rules = {}
      } else {
        this.rules = {
          work_name_cn: [
            { required: true, message: '请输入工作名称(中文)', trigger: 'blur' },
            { min: 1, max: 30, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          work_name_us: [
            { required: true, message: '请输入工作名称(英文)', trigger: 'blur' },
            { min: 1, max: 30, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          department_cn: [
            { required: true, message: '请输入所属部门(中文)', trigger: 'blur' },
            { min: 1, max: 30, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          department_us: [
            { required: true, message: '请输入所属部门(英文)', trigger: 'blur' },
            { min: 1, max: 30, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          work_region_cn: [
            { required: true, message: '请输入工作地区(中文)', trigger: 'blur' },
            { min: 1, max: 30, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          work_region_us: [
            { required: true, message: '请输入工作地区(英文)', trigger: 'blur' },
            { min: 1, max: 30, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          wage: [
            { required: true, message: '请输入薪资', trigger: 'blur' },
            { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' },
          ],
          requirement_cn: [
            { required: true, message: '请输入要求(中文)', trigger: 'blur' },
            { min: 1, max: 255, message: '长度在 1 到 500 个字符', trigger: 'blur' },
          ],
          requirement_us: [
            { required: true, message: '请输入要求(英文)', trigger: 'blur' },
            { min: 1, max: 255, message: '长度在 1 到 500 个字符', trigger: 'blur' },
          ],
          detail_cn: [
            { required: true, message: '请输入详情(中文)', trigger: 'blur' },
          ],
          detail_us: [
            { required: true, message: '请输入详情(英文)', trigger: 'blur' },
          ],
        }
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) {
          this.$message.error('请检查是否完成所有必填项！')
          return
        }
        const result = this.params.data ? await editEmployment(this.ruleForm) : await addEmployment(this.ruleForm)
        if (result.success) {
          this.$message.success('成功')
          this.close()
        }
      })
    },
  },
  computed: {
    
  },
}
</script>
<style scoped lang="less">

</style>
