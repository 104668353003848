export let propList = [
  { prop: 'id',label: 'ID', align: 'center', width: '80', webId: 1, fixed: 'left', },
  { prop: 'work_name_cn', label: '工作名称(中文)', align: 'center', minWidth: '200', showOverflowTooltip: true, copy: true, webId: 2, },
  { prop: 'work_name_us',label: '工作名称(英文)', align: 'center', minWidth: '200', showOverflowTooltip: true, copy: true, webId: 3 },
  { prop: 'department_cn',label: '所属部门(中文)', align: 'center', showOverflowTooltip: true, width: '120', copy: true, webId: 4 },
  { prop: 'department_us',label: '所属部门(英文)', align: 'center', showOverflowTooltip: true, width: '120', copy: true, webId: 5 },
  { prop: 'work_region_cn', label: '工作地区(中文)', minWidth: '150', align: 'center', showOverflowTooltip: true, copy: true, webId: 6},
  { prop: 'work_region_us', label: '工作地区(英文)', minWidth: '150', align: 'center', showOverflowTooltip: true, copy: true, webId: 7 },
  { prop: 'wage', label: '薪资', align: 'center', showOverflowTooltip: true, copy: true, webId: 8 },
  { prop: 'requirement_cn', label: '要求(中文)', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 9 },
  { prop: 'requirement_us', label: '要求(英文)', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 10 },
  { prop: 'detail_cn', label: '详情(中文)', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 11 },
  { prop: 'detail_us', label: '详情(英文)', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 12 },
  { prop: 'create_time', label: '创建时间', minWidth: '200', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 13 },
  { prop: 'update_time', label: '更新时间', minWidth: '200', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 14 }
]
export let operationProps = {
  isShow: true, //设置后面的选择选择条例出不出现
  checkedCities: [1, 2, 3, 4, 5, 6, 7, 8, 13, 14], //配置默认出现的条例对应上方的webId
  checkeList: [
    //配置有哪些条咧id对应上方的webId
    { id: 1, label: 'ID' },
    { id: 2, label: '工作名称(中文)' },
    { id: 3, label: '工作名称(英文)' },
    { id: 4, label: '所属部门(中文)' },
    { id: 5, label: '所属部门(英文)' },
    { id: 6, label: '工作地区(中文)' },
    { id: 7, label: '工作地区(英文)' },
    { id: 8, label: '薪资' },
    { id: 9, label: '要求(中文)' },
    { id: 10, label: '要求(英文)' },
    { id: 11, label: '详情(中文)' },
    { id: 12, label: '详情(英文)' },
    { id: 13, label: '创建时间' },
    { id: 14, label: '更新时间' },
  ],
  width: 200, //配置选择栏的样式 宽带是多少
  align: 'center' //字体居中
}
export let otherProps = {
  showSelectColumn: false, //是否显示多选框
  showFooter: true //显示底部分页
}
